import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { links} from './data';
import {Link} from 'react-router-dom';
import './index.css'

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };
  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = '0px';
    }
  }, [showLinks]);
  return (
    <nav className="navbar-fixed-top border-bottom border-secondary">
      <div className='container-fluid'>
        
      <Container>
      <Row>
      <Col md={4}>
        <div className='nav-header'>
        <Link to="/">
          <p className='nume'>Ionut&nbsp;Stoleriu</p>
        </Link>
          <button className='nav-toggle' onClick={toggleLinks}>
            <FaBars className="button"/>
          </button>
        </div>
        </Col>
        <Col md={8}>
        <div className='links-container' ref={linksContainerRef}>
          <ul className='links' ref={linksRef}>
            {links.map((link) => {
              const { id, url, text } = link;
              return (
                <li key={id}>
                  <a href={url}>{text}</a>
                  <hr/>
                </li>
              );
            })}
          </ul>
        </div>
        </Col>
        </Row>
        </Container>
      </div>
    </nav>
  );
};

export default Navbar;