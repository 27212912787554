import React from 'react';
import './index.css'
import './Loading.css'
const Loading = () => {
  return (
    <div className='sect container text-center'> 
    <div className="div-container1 height">
   <h2>Loading...</h2>
    </div>
    </div>

  )
};

export default Loading;