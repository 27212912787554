import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar';
import './index.css';
import './Services.css';
import './Projects.css';
import './Home.css';
//import Home from './Home'
//import Learning from './Learning';
//import About from './About';
//import Services from './Services';
//import Contact from './Contact';
//import Projects from './Projects';
import Footer from './Footer';
//import Metin2 from './Metin2'
//import Tutorial from './Tutorial'
//import LipSync from './LipSync';
//import Principles from './Principles';
import Loading from './Loading';
const LazyHome = React.lazy(() => import('./Home'));
const LazyLearning = React.lazy(() => import('./Learning'));
const LazyAbout = React.lazy(() => import('./About'));
const LazyServices = React.lazy(() => import('./Services'));
const LazyContact = React.lazy(() => import('./Contact'));
const LazyProjects = React.lazy(() => import('./Projects'));
const LazyTutorial = React.lazy(() => import('./fullsets'));
const LazyDungeons = React.lazy(() => import('./dungeons'));
const LazyLipSync = React.lazy(() => import('./LipSync'));
const LazyPrinciples = React.lazy(() => import('./Principles'));
const LazyMetin2 = React.lazy(() => import('./Metin2'));
const LazyRigging = React.lazy(() => import('./Rigging'));
const LazyRangeOfMovement = React.lazy(() => import('./RangeOfMovement'));
export default function App() {


  return (
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path="/" element={<React.Suspense fallback={<Loading/>}> <LazyHome /> </React.Suspense>}/>
        <Route path="/learning" element={<React.Suspense fallback={<Loading />}> <LazyLearning /> </React.Suspense>}/> 
        <Route path="/about" element={<React.Suspense fallback={<Loading />}> <LazyAbout /> </React.Suspense>}/>
        <Route path="/services" element={<React.Suspense fallback={<Loading/>}> <LazyServices /> </React.Suspense>}/>
        <Route path="/contact" element={<React.Suspense fallback={<Loading />}> <LazyContact /> </React.Suspense>}/>
        <Route path="/projects" element={<React.Suspense fallback={<Loading />}> <LazyProjects /> </React.Suspense>}/>
        <Route path="/metin2" element={<React.Suspense fallback={<Loading/>}> <LazyMetin2 /> </React.Suspense>}/>
        <Route path="/fullsets" element={<React.Suspense fallback={<Loading />}> <LazyTutorial /> </React.Suspense>}/>
        <Route path="/dungeons" element={<React.Suspense fallback={<Loading />}> <LazyDungeons /> </React.Suspense>}/>
        <Route path="/lipsync" element={<React.Suspense fallback={<Loading />}> <LazyLipSync /> </React.Suspense>}/>
        <Route path="/principles" element={<React.Suspense fallback={<Loading/>}> <LazyPrinciples /> </React.Suspense>}/>
        <Route path="/rigging" element={<React.Suspense fallback={<Loading/>}> <LazyRigging /> </React.Suspense>}/>
        <Route path="/rangeofmovement" element={<React.Suspense fallback={<Loading/>}> <LazyRangeOfMovement /> </React.Suspense>}/>
        <Route path="/loading" element={<Loading />}/>
          
      </Routes>
      <Footer/>
    </BrowserRouter>
    
    
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);